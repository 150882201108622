<template>
  <Dialog style="width: 718px" v-model:visible="show" :header="form.id?'编辑热点话题':'发布热点话题'" @hide="hide">
    <div style="padding: 10px 30px">
      <div class="p-grid">
        <div class="p-col-6">
          <div class="p-field">
            <label>标题：</label>
            <InputText @blur="change" style="width: 100%" type="text" v-model="form.title"/>
            <span class="change">{{ mas }}</span>
          </div>
        </div>
        <div class="p-col-6">
          <div class="p-field">
            <label>所属行业：</label>
            <Dropdown @blur="change1" style="width: 100%" v-model="form.industry" :options="industry" optionLabel="name"
                      optionValue="code" placeholder="所属行业" display="chip" :showClear="true"/>
            <span class="change">{{ mas1 }}</span>
          </div>
        </div>
        <div class="p-col-12">
          <div class="p-field">
            <label>简介：</label>
            <textarea cols="2" @blur="change2"
                      style="width: 100%;padding-left: 10px; font-size: 14px; line-height: 30px;border:1px solid #ced4da;border-radius: 3px" type="text"
                      v-model="form.brief"/>
            <span class="change">{{ mas2 }}</span>
          </div>
        </div>
      </div>
      <div class="p-field">
        <label>内容：</label>
        <my-editor v-on:input="setContent" :value="form.content"></my-editor>
<!--        <Editor v-model="form.content" editorStyle="height: 320px"/>-->
        <span class="change">{{ mas3 }}</span>
      </div>
      <div class="p-grid p-jc-center">
        <Button style="width: 120px" @click="submit" label="保存" class="p-mr-2" icon="pi pi-fw pi-save"></Button>
      </div>
    </div>
  </Dialog>
  <Toast/>
</template>

<script>
import myEditor from "@/views/backend/myEditor";

export default {
  components: {myEditor},
  name: "addOrUpdateHotTop",
  data() {
    return {
      mas: null,
      mas1: null,
      mas2: null,
      mas3: null,
      show: false,

      form: {
        id: null,
        title: null,
        brief: null,
        industry: null,
        content: null
      },
      industry: [],
      selectedCity1: null,
    }
  },
  mounted() {
    let ths = this;
    this.$http.get('/dictionary/selectValue', {params: {key: 'suoshuhangye'}}).then((res) => {
      let list = res.data.myValue.split(",");
      let resList = []
      for (let i in list) {
        let item = {}
        item.name = list[i];
        item.code = list[i];
        resList.push(item)
      }
      ths.industry = resList;
    })
  },
  methods: {
    setContent(val) {
      this.form.content = val
    },
    change(e) {
      let b = e.target.value.replace(/^ +| +$/g, '')
      if (b != e.target.value) {
        this.mas = "您输入格式有误，请重新输入"
      } else {
        this.mas = null;
      }
    },
    change1() {
      this.mas1 = null;
    },
    change2() {
      this.mas2 = null;
    },

    init(data) {
      if (data.id) {
        this.form = data
      }
      this.show = true
    },
    hide() {
      this.show = false
      this.$emit('close', true)
    },
    submit() {
      (this.form.title == null | this.form.title == "") ? this.mas = "必填项，请输入" : this.mas = null;
      (this.form.industry == null | this.form.industry == "") ? this.mas1 = "必填项，请选择" : this.mas1 = null;
      (this.form.brief == null | this.form.brief == "") ? this.mas2 = "必填项，请输入" : this.mas2 = null;
      (this.form.content == null | this.form.content == "") ? this.mas3 = "必填项，请输入" : this.mas3 = null;

      if (this.form.title != null & this.form.title != ""
          & this.form.industry != null & this.form.industry != ""
          & this.form.brief != null & this.form.brief != ""
          & this.form.content != null & this.form.content != "") {
        let ths = this;
        this.$http.post('/hotTopic/addHotTopic', this.form).then(() => {
          this.$toast.add({severity: 'success', summary: '成功', life: 3000})
          setTimeout(function () {
            ths.hide()
          }, 3000)
        })
      }

    }
  }
}
</script>

<style scoped>
.change {
  color: red;
}

</style>
