<template>
  <div class="card">


    <Breadcrumb :model="items"/>

    <DataTable class="p-mt-4" ref="dt" :value="list" v-model:selection="selectedProducts" dataKey="id"
               :auto-layout="true"
               :paginator="true" :rows="10"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
               :rowsPerPageOptions="[5,10,25]"
               currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计 {totalRecords} 记录">
      <template #header>
        <div style="display: flex">
          <Button @click="addOrUpdateHotTop" label="发布热点话题" icon="pi pi-plus" class="p-mr-2"></Button>
          <InputText v-model="form.title" placeholder="话题名称"/>
          <Dropdown v-model="form.industry" class="p-ml-3" :options="industry" optionLabel="name"
                    placeholder="选择行业" option-value="name" :show-clear="true"/>
          <Calendar @clear-click="selectByHotTop" v-model="form.time" onfocus=this.blur() style="width: 22.3%"
                    class="p-ml-3"
                    placeholder="发布日期" dateFormat="yy-mm-dd" showButtonBar="true"/>
          <Button class="p-ml-2" @click="selectByHotTop">查询</Button>
        </div>
      </template>
      <Column selectionMode="multiple"></Column>
      <Column field="title" header="话题名称"></Column>
      <Column field="time" header="发布时间">
        <template #body="slotProps">
          {{ new Date(+new Date(slotProps.data.time) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') }}
        </template>
      </Column>
      <Column field="industry" header="所属行业"></Column>
      <Column field="brief" header="简介"></Column>
      <Column field="commNum" header="评论数量"></Column>
      <Column header="操作" style="max-width: 20rem">
        <template #body="slotProps">
          <Button class="p-button-sm p-mr-2" @click="addOrUpdateHotTop(slotProps.data)">编辑</Button>
          <Button class="p-button-sm p-mr-2 p-button-danger" @click="del(slotProps.data.id)">删除</Button>
        </template>
      </Column>
    </DataTable>
  </div>
  <Toast/>
  <add-or-update-hot-top @close="hide" ref="addOrUpdate" v-if="addOrUpdate"></add-or-update-hot-top>

  <ConfirmDialog></ConfirmDialog>
</template>

<script>
import addOrUpdateHotTop from "./addOrUpdateHotTop";

export default {
  name: "HotTopList",
  data() {
    return {
      addOrUpdate: false,
      list: [],
      items: [
        {label: '热点话题', to: '/HotTopList'},
        {label: '话题列表', to: '/HotTopList'},
      ],
      industry: [],
      form: {
        title: null,
        industry: null,
        time: null,
      },
    }
  },
  components: {
    addOrUpdateHotTop
  },
  mounted() {
    this.getData();
    this.init();
  },
  methods: {
    getData() {
      this.$http.get('/hotTopic/getList').then((res) => {
        console.log(res.data)
        this.list = res.data
      })
    },

    selectByHotTop() {
      let time = new Date(this.form.time == "" ? 1970 - 1 - 1 : this.form.time)
      time = time.getFullYear() + '-' + (time.getMonth() + 1) + '-' + time.getDate();
      this.$http.get('/hotTopic/selectByHotTop', {
        params: {
          'title': this.form.title,
          'industry': this.form.industry,
          'time': time
        }
      }).then((res) => {
        this.list = res.data
      })
    },
    init() {
      this.$http.get('/dictionary/selectValue', {params: {key: 'suoshuhangye'}}).then((res) => {
        let list = res.data.myValue.split(",");
        let resList = []
        for (let i in list) {
          let item = {}
          item.name = list[i]
          resList.push(item)
        }
        this.industry = resList;
      })
    },
    addOrUpdateHotTop(data) {
      this.addOrUpdate = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(data)
      })
    },
    hide() {
      this.addOrUpdate = false
      this.getData()
    },
    del(id) {
      this.$confirm.require({
        message: '确认要删除吗？',
        header: '确认',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: "确认",
        rejectLabel: "取消",
        accept: () => {
          this.$http.delete('/hotTopic/delHotTopic?id=' + id).then(() => {
            this.$toast.add({severity: 'success', summary: '成功', life: 3000})
            this.getData()
          })
        }
      });

    }
  }
}
</script>

<style scoped>

</style>
